<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="secondTitle">个人信息</div>
      <div class="profile-wrap" v-loading="loading">
        <div class="avatar-wrap">
          <div class="avatar-main">
            <el-avatar
              :size="90"
              :src="require('@/assets/imgs/userHead.png')"
              @error="true"
              class="avatar"
            >
              <img :src="loginUser.avatarUrl" />
            </el-avatar>
            <div class="btn-edit" @click="chooseImage">
              <i class="icon i-edit"></i>
            </div>
          </div>
          <!-- <div class="btn-change" @click="chooseImage">修改头像</div> -->
        </div>
        <div class="base-wrap">
      
          <div class="item">昵称：{{ loginUser.UserName }}</div>
          <div class="item">性别：{{ loginUser.Sex | matchGender }}</div>
          <div class="item">
            生日：{{ loginUser.Birthday | formatBirthday }}
          </div>
          <div class="item">地区：{{ loginUser.Region || "未知" }}</div>
          <div class="item">签名：{{ loginUser.Autograph || "待完善" }}</div>
          <div class="btn-edit" @click="hiddenProfileEditer = false">
            <i class="el-icon-edit"></i>
            <span>编辑</span>
          </div>
        </div>
      </div>
    </menu-wrapper>

    <!-- 头像裁剪 -->
    <!-- <avatar-cropper :hidden.sync="hiddenAvatarCropper" :imagePath.sync="imagePath" @change="avatarChange"></avatar-cropper> -->
    <!-- 编辑资料 -->
    <profile-editer
      :hidden.sync="hiddenProfileEditer"
      :loginUser="loginUser"
      @change="profileChange"
    ></profile-editer>
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
import profileEditer from "@/components/user/editer/profile-editer.vue";

export default {
  components: {
    menuWrapper,
    profileEditer,
  },
  data() {
    return {
      imagePath: "",
      hiddenAvatarCropper: true,
      hiddenProfileEditer: true,
      loginUser: {},
      loading:false
    };
  },
  created() {
    this.getInfo() 
  },
  computed: {
  },
  filters: {
    matchGender(gender) {
      switch (gender) {
        case 1:
          return "男";
        case 2:
          return "女";
        default:
          return "未知";
      }
    },
    formatBirthday(birthday) {
      if (birthday) {
        const units = ["年", "月", "日"];
        return birthday
          .split("-")
          .map((e, i) => e + units[i])
          .join("");
      }
      return "未知";
    },
  },
  methods: {
    getInfo() {
      this.loading=true
      this.$axios
        .get("/api/User/GetUserInfo", "")
        .then((res) => {
          this.loginUser = res.data;
          this.loading=false
        })
        .catch((err) => {
          this.loading=false
          return Promise.reject(err);
        });
    },
    chooseImage() {},
    //avatarChange(e) {
    // uni.showLoading({
    // 	title: '上传中...',
    // 	mask: true
    // });
    // this.$util.upload('/profile/updateAvatar', e, (res) => {
    // 	uni.hideLoading();

    // 	if (res.state == 'ok') {
    // 		const user = Object.assign({
    // 			...this.loginUser
    // 		}, {
    // 			avatarUrl: res.avatarUrl
    // 		});
    // 		this.$store.commit('login', user);

    // 		this.hiddenAvatarCropper = true;
    // 	} else {
    // 		this.$util.showErrorMsg(res.msg);
    // 	}
    // }, 'avatar');
    //},
    profileChange(e) {
      this.loginUser=e
    },
  },
};
</script>

<style lang="scss">
.profile-wrap {
  // height: 100%;
  @extend %flex-align-center;

  .avatar-wrap {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar-main {
      position: relative;
      width: 90px;
      height: 90px;
      //   border: 2px solid #e3e3e3;
      border-radius: 50%;
      padding: 4px;
      background-color: $bg-color;

      // &:hover {
      //   .btn-edit {
      //     display: block;
      //   }
      // }

      .avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }

      .btn-edit {
        cursor: pointer;
        position: absolute;
        top: 21px;
        left: 21px;
        border: 1px solid $border-color;
        width: 43px;
        height: 43px;
        line-height: 43px;
        border-radius: 50%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;

        .icon {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }

    .btn-change {
      color: #4a90e3;
      padding-top: 15px;
      cursor: pointer;
    }
  }

  .base-wrap {
    flex: 1;
    position: relative;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: $text-color;
      line-height: 40px;
    }

    .item {
      line-height: 40px;
      color: #666;
    }

    .btn-edit {
      position: absolute;
      top: 0;
      right: 0;
      color: #4a90e3;
      line-height: 40px;
      cursor: pointer;

      .icon {
        color: #4a90e3;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
}
</style>
