<template>
  <zw-dialog
    :hidden="hidden"
    title="编辑基础资料"
    :width="440"
    :height="330"
    :showHeaderBg="false"
    :hideFooter="false"
    :showFooterBg="false"
    borderRadius
    confirmText="保存"
    @close="closeDialog"
    @cancel="closeDialog"
    @confirm="submit"
  >
    <div class="form-wrap" >
      <div class="form-item">
        <div class="name">昵称：</div>
        <div class="value">
          <input v-model="user.UserName" placeholder="昵称" maxlength="20" />
        </div>
      </div>
      <div class="form-item">
        <div class="name">性别：</div>
        <el-radio-group class="value" v-model="user.Sex" @change="genderChange">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </div>
      <div class="form-item">
        <div class="name">生日：</div>
        <el-date-picker
          v-model="user.Birthday"
          type="date"
          placeholder="选择生日"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="form-item">
        <div class="name">地区：</div>
        <div class="value pointer" @click="hiddenCityPicker = false">
          <input
            v-model="user.Region"
            placeholder="选择地区"
            maxlength="30"
            disabled
          />
        </div>
      </div>
      <div class="form-item textarea">
        <div class="name">签名：</div>
        <div class="value">
          <textarea
            v-model="user.Autograph"
            placeholder="个性签名"
            maxlength="30"
          ></textarea>
        </div>
      </div>
    </div>

    <!-- 地区选择器 -->
    <div class="city-picker-warpper" v-if="!hiddenCityPicker">
      <city-picker
        :hidden.sync="hiddenCityPicker"
        :deep="2"
        @change="regionChange"
      ></city-picker>
    </div>
  </zw-dialog>
</template>

<script>
import cityPicker from "@/components/user/picker/city-picker.vue";

export default {
  components: {
    cityPicker,
  },
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
    loginUser: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      user: {},
      hiddenCityPicker: true,
    };
  },
  created() {
    this.initUser();
  },
  watch: {
    hidden(e) {
      if (e) {
        this.hiddenDatePicker = this.hiddenCityPicker = true;
        this.loading=true
      }
    },
    loginUser(e){
      this.initUser();
    }
    // user(e){
    //   console.log(e,"user")
    //   this.loginUser=e
    // }
  },
  methods: {
    initUser() {
      const user = {
        ...this.loginUser,
      };
      for (let key in user) {
        if (!user[key]) user[key] = "";
      }
      this.user = user;
      
    },
    closeDialog() {
      this.$emit("update:hidden", true);
    },
    genderChange(e) {
      this.user.Sex = e;
    },
    regionChange(e) {
      let region = e.Province;
      if (e.City.indexOf(e.Province) == -1) region += "省" + e.City;
      this.user.Region = region;
      this.hiddenCityPicker = true;
    },
    submit() {
      // if (!this.$regular.nickName.test(this.user.UserName)) {
      //   return this.notifyError("请输入正确的昵称");
      // }
      if (this.user.Sex < 1) {
        return this.notifyError("请选择性别");
      }
      this.$axios
        .post("/api/User/UpdateUserInfo", this.user)
        .then((res) => {
          this.closeDialog();
          this.notifySucceed("基础资料修改成功");
          this.$store.dispatch("upUserName",this.user.UserName)
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      this.$emit("change", this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  width: 100%;
  padding: 20px;

  .form-item {
    position: relative;
    padding-bottom: 15px;
    height: 40px;
    @extend %flex-align-center;

    .name {
      width: 60px;
      margin-right: 10px;
      text-align: right;
      // font-weight: bold;
      color: #666;
    }

    .value {
      width: 320px;
      color: $text-color;

      &.pointer {
        &,
        ::v-deep .uni-input-input {
          cursor: pointer;
        }
      }

      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border: 1px solid $border-color;
      }

      label {
        margin-right: 20px;

        radio {
          transform: scale(0.8);
        }
      }
    }

    &.textarea {
      align-items: flex-start;
      height: 100px;

      textarea {
        width: 298px;
        padding: 10px;
        height: 60px;
        border: 1px solid $border-color;
        font-family:auto;
      }
    }
  }
}

.city-picker-warpper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 285px;
}
</style>
